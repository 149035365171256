import { Component } from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'salik-call-center-ui';
  constructor(){
    console.log(`Health check times--->${environment.deploymentTime}`)
  }
}
